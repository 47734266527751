import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

import AuthContext from "src/AuthContext";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <AuthContext>
      <App />
      <div id="BACKGROUND" />
    </AuthContext>
  );
}
