import { JwtPayload, jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";

export interface AuthContextI {
  userToken:
    | null
    | (JwtPayload & {
        email: string;
        roles: string[];
        userId: string;
        firstName: string;
        lastName: string;
        team_id: string;
        team_name: string;
        team_logo_url: string;
      });
  updateUserToken: () => void;
  isAdmin: boolean;
}

export const AuthContext = createContext<AuthContextI>({
  userToken: null,
  isAdmin: false,
  updateUserToken: () => undefined
});
export const ACCESS_TOKEN_KEY = "accessToken";

const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

const AuthContextProvider = ({ children }) => {
  const [decodedToken, setDecodedToken] = useState({} as any);

  const { roles } = decodedToken;

  const isAdmin = roles ? roles.includes("ADMIN") : false;

  const handleToken = () => {
    const token = getToken();
    if (!token) {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    } else {
      setDecodedToken(jwtDecode(token));
    }
  };

  useEffect(() => {
    handleToken();
  }, []);

  return (
    <AuthContext.Provider value={{ userToken: decodedToken, isAdmin, updateUserToken: handleToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
