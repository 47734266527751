import { ThemeOptions, createTheme } from "@mui/material";
import { blue, teal } from "@mui/material/colors";
import { MRT_RowData, MaterialReactTableProps } from "material-react-table";

const buttonOverride = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === "contained" && {
        color: "#fff"
      })
    })
  }
};

export const MAIN_COLOR = teal[400];

export const darkTheme: ThemeOptions = {
  palette: {
    primary: {
      dark: teal[600],
      main: MAIN_COLOR,
      light: teal[50]
    },
    secondary: {
      main: blue[400]
    },
    background: {
      paper: "#363636",
      default: "#363636"
    },
    mode: "dark" as const
  },
  components: {
    MuiButton: buttonOverride
  }
};

export const lightTheme: ThemeOptions = {
  palette: {
    primary: {
      dark: teal[800],
      main: teal[400],
      light: teal[50]
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff"
    },
    background: {
      paper: "#fff",
      default: "#fcfcfe"
    },
    mode: "light" as const
  }
};

export const transparentTableTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    background: {
      paper: "transparent",
      default: "#nnn"
    }
  }
});

export const getCommonMaterialReactTableProps = <T extends MRT_RowData>(): Partial<MaterialReactTableProps<T>> => ({
  muiTableProps: { sx: { height: "100%" } },
  muiTablePaperProps: {
    sx: { height: "100%", display: "flex", flexDirection: "column" },
    elevation: 0
  },
  muiTableHeadProps: { sx: {} },
  muiTableBodyProps: {
    sx: { backgroundColor: "transparent", maxHeight: "100%", overflow: "auto", overflowX: "hidden" }
  },
  muiTableHeadRowProps: { sx: { backgroundColor: "#00000020" } },
  muiTableHeadCellProps: {
    sx: { backgroundColor: "transparent", borderColor: "#ffffff00" }
  },
  muiTableContainerProps: { sx: { position: "relative" } },
  muiBottomToolbarProps: { sx: { backgroundColor: "transparent", borderTop: "1px solid #ffffff50" } },
  muiTableBodyCellProps: { sx: { borderColor: "#ffffff50" } }
});
