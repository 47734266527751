import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

const LoginPage = lazy(() => import("./layout/LoginPage"));
const Layout = lazy(() => import("./layout/Layout"));
const HomePage = lazy(() => import("src/components/HomePage"));
const TeamScatterPage = lazy(() => import("src/components/pages/TeamScatterPage"));
const TeamScatterByDatePage = lazy(() => import("src/components/pages/TeamScatterByDatePage"));
const ShotChartPage = lazy(() => import("src/components/pages/ShotCharts"));
const TrendsPage = lazy(() => import("src/components/pages/TrendsPage/TrendsPage"));
const AllTeamsTrends = lazy(() => import("src/components/pages/TrendsPage/AllTeamsTrends"));
const AllPlayersTrends = lazy(() => import("src/components/pages/TrendsPage/AllPlayersTrends"));
const PdfExport = lazy(() => import("src/components/pages/PdfExport"));
const LineupsPage = lazy(() => import("src/components/pages/Lineups"));

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <HomePage />
      },
      {
        path: "team-scatter",
        children: [{ path: "", element: <TeamScatterPage /> }]
      },
      {
        path: "team-scatter-by-date",
        children: [{ path: "", element: <TeamScatterByDatePage /> }]
      },
      {
        path: "trends",
        children: [{ path: "", element: <TrendsPage /> }]
      },
      {
        path: "shot-charts",
        children: [{ path: "", element: <ShotChartPage /> }]
      },
      {
        path: "pdf-export",
        children: [{ path: "", element: <PdfExport /> }]
      },
      {
        path: "lineups",
        children: [{ path: "", element: <LineupsPage /> }]
      },
      {
        path: "players-trends-by-stat",
        children: [{ path: "", element: <AllPlayersTrends /> }]
      },
      {
        path: "teams-trends-by-stat",
        children: [{ path: "", element: <AllTeamsTrends /> }]
      }
    ]
  }
]);
